;(function() {
  const FALLBACK_GLOBAL_HEADER = {
    "data": {
      "globalNavigationHeaderList": {
        "items": [
          {
            "manageBookingNavigation": {
              "mainBookingMenuLinks": [
                {
                  "menuTextLink": "Booking Details",
                  "iconImagePath": null,
                  "isFeatured": false,
                  "isCtaDisabled": true,
                  "isExternal": false,
                  "internalPath": null,
                  "externalUrl": null,
                  "openInNewTab": false,
                  "linkType": "default",
                  "princesscomAppLinks": null,
                  "flagMessage": null,
                  "haspromotion": true,
                  "promotionpath": {
                    "title": "Bid for a Stateroom Upgrade",
                    "image": {
                      "mimeType": "image/png",
                      "_path": "/content/dam/test/nav-deals-desktop.png"
                    },
                    "ctaText": "Learn more",
                    "isExternal": true,
                    "externalPath": "https://www.princess.com/en-us/plan/princess-cruise-room-upgrade",
                    "internalPath": null,
                    "description": {
                      "html": "\u003Cp\u003EMake your cruise experience even better with just a bid\u003C/p\u003E\n"
                    }
                  },
                  "sublink": [
                    {
                      "menuTextLink": "Booking Summary",
                      "iconImagePath": null,
                      "isFeatured": false,
                      "isCtaDisabled": false,
                      "isExternal": true,
                      "internalPath": null,
                      "externalUrl": "/manage-booking/summary.page",
                      "openInNewTab": false,
                      "linkType": "default",
                      "princesscomAppLinks": null,
                      "flagMessage": null,
                      "haspromotion": false,
                      "promotionpath": null,
                      "sublink": []
                    },
                    {
                      "menuTextLink": "Cruise Invoice",
                      "iconImagePath": null,
                      "isFeatured": false,
                      "isCtaDisabled": false,
                      "isExternal": true,
                      "internalPath": null,
                      "externalUrl": "/manage-booking/paymentsAndCredits.page",
                      "openInNewTab": false,
                      "linkType": "default",
                      "princesscomAppLinks": null,
                      "flagMessage": null,
                      "haspromotion": false,
                      "promotionpath": null,
                      "sublink": []
                    },
                    {
                      "menuTextLink": "Travel Itinerary",
                      "iconImagePath": null,
                      "isFeatured": false,
                      "isCtaDisabled": false,
                      "isExternal": true,
                      "internalPath": null,
                      "externalUrl": "/manage-booking/itinerary.page",
                      "openInNewTab": false,
                      "linkType": "default",
                      "princesscomAppLinks": null,
                      "flagMessage": "itinerary",
                      "haspromotion": false,
                      "promotionpath": null,
                      "sublink": []
                    },
                    {
                      "menuTextLink": "To-Do-List",
                      "iconImagePath": null,
                      "isFeatured": false,
                      "isCtaDisabled": true,
                      "isExternal": false,
                      "internalPath": null,
                      "externalUrl": null,
                      "openInNewTab": false,
                      "linkType": "default",
                      "princesscomAppLinks": null,
                      "flagMessage": null,
                      "haspromotion": false,
                      "promotionpath": null,
                      "sublink": [
                        {
                          "menuTextLink": "Prepay Tips",
                          "iconImagePath": null,
                          "isFeatured": false,
                          "isCtaDisabled": false,
                          "isExternal": true,
                          "internalPath": null,
                          "externalUrl": "/manage-booking/paymentsAndCredits.page?accordion=gratuities",
                          "openInNewTab": false,
                          "linkType": "default",
                          "princesscomAppLinks": null,
                          "flagMessage": null,
                          "haspromotion": false,
                          "promotionpath": null,
                          "sublink": []
                        },
                        {
                          "menuTextLink": "Print Luggage Tags",
                          "iconImagePath": null,
                          "isFeatured": false,
                          "isCtaDisabled": false,
                          "isExternal": true,
                          "internalPath": null,
                          "externalUrl": "/manage-booking/documents.page",
                          "openInNewTab": false,
                          "linkType": "default",
                          "princesscomAppLinks": null,
                          "flagMessage": null,
                          "haspromotion": false,
                          "promotionpath": null,
                          "sublink": []
                        },
                        {
                          "menuTextLink": "Check In",
                          "iconImagePath": null,
                          "isFeatured": false,
                          "isCtaDisabled": false,
                          "isExternal": true,
                          "internalPath": null,
                          "externalUrl": "/manage-booking/paxCheckIn.page",
                          "openInNewTab": false,
                          "linkType": "default",
                          "princesscomAppLinks": null,
                          "flagMessage": null,
                          "haspromotion": false,
                          "promotionpath": null,
                          "sublink": []
                        },
                        {
                          "menuTextLink": "Bed Configuration",
                          "iconImagePath": null,
                          "isFeatured": false,
                          "isCtaDisabled": false,
                          "isExternal": true,
                          "internalPath": null,
                          "externalUrl": "/manage-booking/paxCheckIn.page?accordion=bed-config",
                          "openInNewTab": false,
                          "linkType": "default",
                          "princesscomAppLinks": null,
                          "flagMessage": null,
                          "haspromotion": false,
                          "promotionpath": null,
                          "sublink": []
                        },
                        {
                          "menuTextLink": "Provide Flight Info",
                          "iconImagePath": null,
                          "isFeatured": false,
                          "isCtaDisabled": false,
                          "isExternal": true,
                          "internalPath": null,
                          "externalUrl": "/manage-booking/flightTransfer.page?accordion=independent-flight",
                          "openInNewTab": false,
                          "linkType": "default",
                          "princesscomAppLinks": null,
                          "flagMessage": null,
                          "haspromotion": false,
                          "promotionpath": null,
                          "sublink": []
                        },
                        {
                          "menuTextLink": "Onboard Language Preference",
                          "iconImagePath": null,
                          "isFeatured": false,
                          "isCtaDisabled": false,
                          "isExternal": true,
                          "internalPath": null,
                          "externalUrl": "/manage-booking/paxCheckIn.page?accordion=onboard-lang",
                          "openInNewTab": false,
                          "linkType": "default",
                          "princesscomAppLinks": null,
                          "flagMessage": null,
                          "haspromotion": false,
                          "promotionpath": null,
                          "sublink": []
                        },
                        {
                          "menuTextLink": "Vacation Protection",
                          "iconImagePath": null,
                          "isFeatured": false,
                          "isCtaDisabled": false,
                          "isExternal": true,
                          "internalPath": null,
                          "externalUrl": "/manage-booking/insurance.page",
                          "openInNewTab": false,
                          "linkType": "default",
                          "princesscomAppLinks": null,
                          "flagMessage": null,
                          "haspromotion": false,
                          "promotionpath": null,
                          "sublink": []
                        }
                      ]
                    },
                    {
                      "menuTextLink": "Bid for Stateroom Upgrade",
                      "iconImagePath": null,
                      "isFeatured": false,
                      "isCtaDisabled": false,
                      "isExternal": true,
                      "internalPath": null,
                      "externalUrl": "/plan/princess-cruise-room-upgrade",
                      "openInNewTab": false,
                      "linkType": "default",
                      "princesscomAppLinks": "www",
                      "flagMessage": null,
                      "haspromotion": false,
                      "promotionpath": null,
                      "sublink": []
                    },
                    {
                      "menuTextLink": "Onboard Purchases",
                      "iconImagePath": null,
                      "isFeatured": false,
                      "isCtaDisabled": true,
                      "isExternal": false,
                      "internalPath": null,
                      "externalUrl": null,
                      "openInNewTab": false,
                      "linkType": "default",
                      "princesscomAppLinks": null,
                      "flagMessage": null,
                      "haspromotion": false,
                      "promotionpath": null,
                      "sublink": [
                        {
                          "menuTextLink": "Spa Reservations",
                          "iconImagePath": null,
                          "isFeatured": false,
                          "isCtaDisabled": false,
                          "isExternal": true,
                          "internalPath": null,
                          "externalUrl": "/manage-booking/onboard/spa.page",
                          "openInNewTab": false,
                          "linkType": "default",
                          "princesscomAppLinks": "book",
                          "flagMessage": null,
                          "haspromotion": false,
                          "promotionpath": null,
                          "sublink": []
                        },
                        {
                          "menuTextLink": "Onboard Reservations & Purchases",
                          "iconImagePath": null,
                          "isFeatured": false,
                          "isCtaDisabled": false,
                          "isExternal": true,
                          "internalPath": null,
                          "externalUrl": "/manage-booking/onboard/home.page",
                          "openInNewTab": false,
                          "linkType": "default",
                          "princesscomAppLinks": "book",
                          "flagMessage": null,
                          "haspromotion": false,
                          "promotionpath": null,
                          "sublink": []
                        },
                        {
                          "menuTextLink": "Excursions",
                          "iconImagePath": null,
                          "isFeatured": false,
                          "isCtaDisabled": false,
                          "isExternal": true,
                          "internalPath": null,
                          "externalUrl": "/cruise-excursions/cp-landing",
                          "openInNewTab": false,
                          "linkType": "default",
                          "princesscomAppLinks": "www",
                          "flagMessage": "Online purchase is closed, but excursions are still available onboard",
                          "haspromotion": false,
                          "promotionpath": null,
                          "sublink": []
                        }
                      ]
                    }
                  ]
                },
                {
                  "menuTextLink": "Cruise Packages",
                  "iconImagePath": null,
                  "isFeatured": false,
                  "isCtaDisabled": false,
                  "isExternal": true,
                  "internalPath": null,
                  "externalUrl": "/manage-booking/onboard/cruisePackages.page",
                  "openInNewTab": false,
                  "linkType": "default",
                  "princesscomAppLinks": null,
                  "flagMessage": null,
                  "haspromotion": true,
                  "promotionpath": {
                    "title": "Lorem Ipsum Dolor",
                    "image": {
                      "mimeType": "image/png",
                      "_path": "/content/dam/princess/cfm-assets-demo/Promotion Image Generic.png"
                    },
                    "ctaText": "Learn More",
                    "isExternal": false,
                    "externalPath": null,
                    "internalPath": {
                      "_path": "/content/princess/en-us"
                    },
                    "description": {
                      "html": "\u003Cp\u003ELorem Ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non consequat erat.&nbsp;\u003C/p\u003E\n"
                    }
                  },
                  "sublink": []
                },
                {
                  "menuTextLink": "Excursions",
                  "iconImagePath": null,
                  "isFeatured": false,
                  "isCtaDisabled": false,
                  "isExternal": true,
                  "internalPath": null,
                  "externalUrl": "/cruise-excursions/cp-landing",
                  "openInNewTab": false,
                  "linkType": "default",
                  "princesscomAppLinks": "www",
                  "flagMessage": "Online purchase is closed, but excursions are still available onboard",
                  "haspromotion": false,
                  "promotionpath": null,
                  "sublink": []
                },
                {
                  "menuTextLink": "Flight & Transfers",
                  "iconImagePath": null,
                  "isFeatured": false,
                  "isCtaDisabled": false,
                  "isExternal": true,
                  "internalPath": null,
                  "externalUrl": "/manage-booking/flightTransfer.page",
                  "openInNewTab": false,
                  "linkType": "default",
                  "princesscomAppLinks": "book",
                  "flagMessage": null,
                  "haspromotion": false,
                  "promotionpath": null,
                  "sublink": []
                },
                {
                  "menuTextLink": "Food & Drink",
                  "iconImagePath": null,
                  "isFeatured": false,
                  "isCtaDisabled": true,
                  "isExternal": false,
                  "internalPath": null,
                  "externalUrl": null,
                  "openInNewTab": false,
                  "linkType": "default",
                  "princesscomAppLinks": null,
                  "flagMessage": null,
                  "haspromotion": true,
                  "promotionpath": {
                    "title": "Save Big On Things You Care About The Most",
                    "image": {
                      "mimeType": "image/png",
                      "_path": "/content/dam/test/nav-fewd-and-drink-desktop.png"
                    },
                    "ctaText": "Learn More",
                    "isExternal": true,
                    "externalPath": "https://www.princess.com/en-us/cruise-deals-promotions/plus-premier-cruise-packages",
                    "internalPath": null,
                    "description": {
                      "html": "\u003Cp\u003EEnjoy unlimited dining at our specialty restaurants included in your fare when you book Princess Premier!\u003C/p\u003E\n"
                    }
                  },
                  "sublink": [
                    {
                      "menuTextLink": "Beverages Packages",
                      "iconImagePath": null,
                      "isFeatured": false,
                      "isCtaDisabled": false,
                      "isExternal": true,
                      "internalPath": null,
                      "externalUrl": "/manage-booking/onboard/home.page?service=beverages&interstitial=true",
                      "openInNewTab": false,
                      "linkType": "default",
                      "princesscomAppLinks": "book",
                      "flagMessage": null,
                      "haspromotion": false,
                      "promotionpath": null,
                      "sublink": []
                    },
                    {
                      "menuTextLink": "Dining Experiences & Packages",
                      "iconImagePath": null,
                      "isFeatured": false,
                      "isCtaDisabled": false,
                      "isExternal": true,
                      "internalPath": null,
                      "externalUrl": "/manage-booking/onboard/viewservicelisting.page?service=culinary&items=0635,0636,3013,3012,6071,3014,3015,3016,3020,3017,3018,3019",
                      "openInNewTab": false,
                      "linkType": "default",
                      "princesscomAppLinks": "book",
                      "flagMessage": null,
                      "haspromotion": false,
                      "promotionpath": null,
                      "sublink": []
                    },
                    {
                      "menuTextLink": "Wine & Spirits Delivery",
                      "iconImagePath": null,
                      "isFeatured": false,
                      "isCtaDisabled": false,
                      "isExternal": true,
                      "internalPath": null,
                      "externalUrl": "/manage-booking/onboard/viewservicelisting.page?service=cellars&items=0478,0439,0479,0741,0740,0742,0476,0443,0744,0745,0746,0747,2047,0448,0749,0750,0751,0752,0753,0454,0755,0756,0757,0758,0475,0472,2046,0465,0769,0466,0764,0459,",
                      "openInNewTab": false,
                      "linkType": "default",
                      "princesscomAppLinks": "book",
                      "flagMessage": null,
                      "haspromotion": false,
                      "promotionpath": null,
                      "sublink": []
                    }
                  ]
                },
                {
                  "menuTextLink": "Spa",
                  "iconImagePath": null,
                  "isFeatured": false,
                  "isCtaDisabled": false,
                  "isExternal": true,
                  "internalPath": null,
                  "externalUrl": "/manage-booking/onboard/spa.page",
                  "openInNewTab": false,
                  "linkType": "default",
                  "princesscomAppLinks": "book",
                  "flagMessage": null,
                  "haspromotion": false,
                  "promotionpath": null,
                  "sublink": []
                },
                {
                  "menuTextLink": "Onboard Extras",
                  "iconImagePath": null,
                  "isFeatured": false,
                  "isCtaDisabled": false,
                  "isExternal": true,
                  "internalPath": null,
                  "externalUrl": "/manage-booking/onboard/home.page",
                  "openInNewTab": false,
                  "linkType": "default",
                  "princesscomAppLinks": "book",
                  "flagMessage": null,
                  "haspromotion": false,
                  "promotionpath": null,
                  "sublink": []
                }
              ]
            },
          }
        ]
      }
    }
  };

  const MB_NAV_MENU = 'mb-nav-menu';
  const MOBILE_MB_NAV_MENU = 'mobile-mb-nav-menu';
  const MENU_CONTAINER_ID = 'menuContainer';

  const CLASSES = Object.fromEntries(Object.entries({
    mb: "mb",
    cc: "cc",
    open: "open",
    disabled: "disabled",
    mobile: "mobile",
    desktop: "desktop",
    header: "header",
    nonStackingContext: "nonStackingContext",
    logo: "logo",
    tooltip: "tooltip",
    up: "up",
    down: "down",
    burgerBtn: "burgerBtn",
    burgerBtnText: "burgerBtnText",
    searchBtn: "searchBtn",
    expandBtn: "expandBtn",
    expandBtnText: "expandBtnText",
    chevron: "chevron",
    closeBtn: "closeBtn",
    closeBtnText: "closeBtnText",
    link: "link",
    linkText: "linkText",
    layer: "layer",
    nav: "nav",
    scrollContainer: "scrollContainer",
    menuWrapper: "menuWrapper",
    menu: "menu",
    menuItem: "menuItem",
    menuControlPanel: "menuControlPanel",
    menuControlPanelBackBtn: "menuControlPanelBackBtn",
    menuControlPanelBackBtnText: "menuControlPanelBackBtnText",
    menuControlPanelTitle: "menuControlPanelTitle",
    menuControlPanelCloseBtn: "menuControlPanelCloseBtn",
    menuControlPanelCloseBtnText: "menuControlPanelCloseBtn",
    submenu: "submenu",
    submenuItem: "submenuItem",
    promo: "promo",
    promoImg: "promoImg",
    promoTitle: "promoTitle",
    promoDescription: "promoDescription",
    promoLink: "promoLink",
  }).map(([key, value]) => ([
    key, 'mb-nav-menu__' + value
  ])));

  const ATTRIBUTES = {
    ariaExpanded: 'aria-expanded',
    ariaLabel: 'aria-label',
    dataTrack: 'data-track',
    dataTrackId: 'data-track-id',
    dataComponentLevel: 'data-track-component-level-1',
  };

  const BREAKPOINTS = {
    xs: 1025
  }

  let PAGE_WIDTH = getPageWidth();

  async function fetchGlobalHeader() {
    try {
      const response = await fetch(`${$wwwURL}/graphql/execute.json/princess/globalheader`);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching global header data:', error);

    }
  }

  window.addEventListener("resize", () => {
    PAGE_WIDTH = getPageWidth();
  });

  function getRootElement(pageWidth) {
    if(pageWidth === undefined) return document;

    return pageWidth >= BREAKPOINTS.xs ? document.querySelector(MB_NAV_MENU) : document.querySelector(MOBILE_MB_NAV_MENU)
  }

  function getPageWidth () {
    if (self.innerWidth) {
      return self.innerWidth;
    }

    if (document.documentElement && document.documentElement.clientHeight) {
      return document.documentElement.clientWidth;
    }

    if (document.body) {
      return document.body.clientWidth;
    }

    return 0;
  }

  function getMainBookingMenuLinks (response) {
    if (!response || typeof response !== 'object') {
      return [];
    }

    return response?.data?.globalNavigationHeaderList?.items?.find(item => item.manageBookingNavigation)?.manageBookingNavigation?.mainBookingMenuLinks;
  }

  function defineDomain(princesscomAppLinks) {
    if(!princesscomAppLinks) return $bookURL;

    if(princesscomAppLinks.includes('www')) {
      return $wwwURL;
    }

    if(princesscomAppLinks.includes('book')) {
      return $bookURL;
    }

    return $bookURL;
  }

  function normalizeLink({
    princesscomAppLinks,
    isExternal,
    externalUrl,
    externalPath,
    internalPath
  }) {
    const removeContentPrincess = (path) => {
      if (!path) return path;
      return path.replace(/\/content\/princess/, '');
    };

    if (externalPath) {
      return removeContentPrincess(externalPath);
    }
  
    const domain = defineDomain(princesscomAppLinks);
    let path = '';
  
    if (isExternal) {
      path = externalUrl ?? '';
    } else {
      path = internalPath?.["_path"] ?? '';
    }

    path = removeContentPrincess(path);
  
    return domain + path;
  }

  function clearInlineStyles ($element) {
    if (!$element) return;
    $element?.removeAttribute("style");
  }

  function setMinHeightForNav () {
    if(PAGE_WIDTH < BREAKPOINTS.xs) return;

    const $nav = getRootElement(PAGE_WIDTH).querySelector(`.${CLASSES.nav}`);
    const $expandedSubmenus = [...$nav.querySelectorAll(`.${CLASSES.submenu}.${CLASSES.open}`)];
    const $promo = $nav.querySelector(`.${CLASSES.submenu}.${CLASSES.open} .${CLASSES.promo}`)
    const $header = $nav.querySelector(`.${CLASSES.header}`)

    clearInlineStyles($nav)
    $expandedSubmenus.forEach($el => clearInlineStyles($el));

    const minHeight = Math.max(...[...$expandedSubmenus, $promo].filter(Boolean).map($el => {
      return $el.offsetHeight;
    }))

    $nav.style.minHeight = minHeight + $header.offsetHeight +  "px";
    $expandedSubmenus.forEach($el => $el.style.minHeight = minHeight + "px");
  }

  function setLeftOffsetPromo () {
    if(PAGE_WIDTH < BREAKPOINTS.xs) return;

    const $nav = getRootElement(PAGE_WIDTH).querySelector(`.${CLASSES.nav}`);
    const $expandedSubmenus = [...$nav.querySelectorAll(`.${CLASSES.submenu}.${CLASSES.open}`)];
    const $promo = $nav.querySelector(`.${CLASSES.submenu}.${CLASSES.open} .${CLASSES.promo}`);

    if ($promo) {
      const offsetPromo = $expandedSubmenus.map(($el) => $el.offsetWidth).reduce((a, b) => a + b, 0);
      $promo.style.marginLeft = offsetPromo + 'px';
      const navWidth = $nav.offsetWidth;

      $promo.style.width =  navWidth - offsetPromo + 'px';
    }
  }

  function closeAll() {
    const $nav = getRootElement(PAGE_WIDTH).querySelector(`.${CLASSES.nav}`);
    const $header = $nav.querySelector(`.${CLASSES.header}`);
    const $expandBtns = $nav.querySelectorAll(`[${ATTRIBUTES.ariaExpanded}="true"]`);
    const $AllOpenedMenus = $nav.querySelectorAll(`.${CLASSES.open}`);

    clearInlineStyles($nav);

    $expandBtns.forEach(btn => btn.setAttribute(ATTRIBUTES.ariaExpanded, "false"));
    $AllOpenedMenus.forEach(menu => menu.classList.remove(CLASSES.open));
    $nav.classList.remove(CLASSES.open);
    $header?.classList.remove(CLASSES.open);

    if($expandBtns && $expandBtns[0]) {
      $expandBtns[0].focus();
    }
  }

  function closeAllExpandedSubmenus($menu) {
    const $expandBtns = $menu.querySelectorAll(`[${ATTRIBUTES.ariaExpanded}="true"]`);
    const $openedSubmenus = $menu.querySelectorAll(`.${CLASSES.open}`);

    $expandBtns.forEach(btn => btn.setAttribute(ATTRIBUTES.ariaExpanded, "false"));
    $openedSubmenus.forEach(menu => menu.classList.remove(CLASSES.open));
  }

  function toggleExpandBtnAndMenu ({
    $expandBtn,
    $submenu,
    ariaExpandedValue
  }) {
    if (ariaExpandedValue === "true") {
      $expandBtn.setAttribute(ATTRIBUTES.ariaExpanded, "false");
      $submenu.classList.remove(CLASSES.open);
    } else {
      $expandBtn.setAttribute(ATTRIBUTES.ariaExpanded, "true");
      $submenu.classList.add(CLASSES.open);
    }
  }

  function handleClickExpandMenuBtn ($submenu, $expandBtn) {
    return function (event) {
      const $rootElement = getRootElement(PAGE_WIDTH);
      const $header = $rootElement.querySelector(`.${CLASSES.header}`);
      const $menu = $rootElement.querySelector(`.${CLASSES.menu}`);
      const $closeMenuBtns = $menu.querySelectorAll(`.${CLASSES.closeBtn}.${CLASSES.desktop}`);

      const ariaExpandedValue = $expandBtn.getAttribute(ATTRIBUTES.ariaExpanded);

      if($header) $closeMenuBtns.forEach($el => $el.style.left = $header.offsetWidth - 60 + 'px');

      if(ariaExpandedValue === "true") {
        $header?.classList.remove(CLASSES.open)
      } else {
        $header?.classList.add(CLASSES.open)
      }

      closeAllExpandedSubmenus($menu);
      toggleExpandBtnAndMenu({ $expandBtn, $submenu, ariaExpandedValue });

      setMinHeightForNav();
      setLeftOffsetPromo();

      event.preventDefault();
      event.stopPropagation();
    }
  }

  function handleClickExpandSubmenuBtn ({
    $submenu, $expandBtn, layer
  }) {
    return function (event) {
      const ariaExpandedValue = $expandBtn.getAttribute(ATTRIBUTES.ariaExpanded);
      closeAllExpandedSubmenus($expandBtn.parentElement.parentElement);
      toggleExpandBtnAndMenu({ $expandBtn, $submenu, ariaExpandedValue });

      setMinHeightForNav();
      setLeftOffsetPromo();

      if(layer >= 2 && PAGE_WIDTH < BREAKPOINTS.xs && ariaExpandedValue === "false"){
        document.getElementById(MENU_CONTAINER_ID)?.classList.add(CLASSES.nonStackingContext);
      }

      event.preventDefault();
      event.stopPropagation();
    }
  }

  function handleClickBackBtn ($submenu, $expandBtn) {
    return function (event) {
      $expandBtn.setAttribute(ATTRIBUTES.ariaExpanded, "false");
      $submenu.classList.remove(CLASSES.open)
      document.getElementById(MENU_CONTAINER_ID)?.classList.remove(CLASSES.nonStackingContext);

      event.preventDefault();
      event.stopPropagation();
    }
  }

  function handleClickCloseBtn (event) {
    closeAll(event);
    const $burgerBtn = getRootElement(PAGE_WIDTH).querySelector(`.${CLASSES.burgerBtn}`);
    $burgerBtn?.classList.remove(CLASSES.open);
    document.getElementById(MENU_CONTAINER_ID)?.classList.remove(CLASSES.nonStackingContext);

    event.preventDefault();
    event.stopPropagation();
  }

  function createElement({
    tag,
    classes = [],
    attributes = {},
    eventListeners = {},
    children = [],
    innerHTML,
    textContent,
  }) {
    const $element = document.createElement(tag);
    if (classes && Array.isArray(classes)) $element.classList.add(...classes);
    if (attributes && typeof attributes === 'object') Object.entries(attributes).forEach(([att, value]) => {
      $element.setAttribute(att, value);
    });
    if (eventListeners && typeof eventListeners === 'object') Object.entries(eventListeners).forEach(([eventName, handler]) => {
      $element.addEventListener(eventName, handler);
    });
    if (children && Array.isArray(children)) children.forEach((child => {
      if(child) {
        $element.appendChild(child)
      };
    }));
    if (textContent) $element.textContent = textContent;
    if (innerHTML) $element.innerHTML = innerHTML;

    return $element;
  }

  function createBackBtn($submenu, $expandBtn) {
    if(!$submenu || !$expandBtn) return;

    return createElement({
      tag: 'button',
      attributes: {
        [ATTRIBUTES.dataTrack]: "primaryNav",
        [ATTRIBUTES.dataTrackId]: "back-button",
        "aria-label": "back button"
      },
      classes: [CLASSES.menuControlPanelBackBtn],
      eventListeners: { 'click': handleClickBackBtn($submenu, $expandBtn) },
      children: [
        createElement({tag:'div', innerHTML: `<svg aria-hidden="true" focusable="false" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.15271 1L1.15271 7L7.15271 13" stroke="#020215" stroke-linejoin="round"/>
        </svg>
        `}),
        createElement({tag:'div',  classes: [CLASSES.menuControlPanelBackBtnText],  textContent: "Back"}),
      ]


    })
  }

  function createCloseBtn(classes = []) {
    return createElement({ 
    tag: 'button', 
    attributes: {
      [ATTRIBUTES.dataTrack]: "primaryNav",
      [ATTRIBUTES.dataTrackId]: "close-button",
      "aria-label": "close button"
    },
    classes: classes, eventListeners: { 'click': handleClickCloseBtn },
    children: [
      createElement({ tag: 'div', innerHTML: `<svg aria-hidden="true" focusable="false" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.43539 7.25485L14.5765 1.11373L13.9227 0.459961L7.78161 6.60108L1.77185 0.591313L1.11808 1.24509L7.12784 7.25485L0.84729 13.5354L1.50106 14.1892L7.78161 7.90862L14.1935 14.3205L14.8473 13.6668L8.43539 7.25485Z" fill="#020215"/>
      </svg>` }),
      createElement({ tag: 'div', textContent: "Close", classes: [CLASSES.closeBtnText] }),
    ]

  })
  }

  function createMenuControlPanel({
    menuTextLink,
    $submenu,
    $expandBtn
  }) {
    return createElement({
      tag: 'div',
      classes: [CLASSES.menuControlPanel],
      children: [
        createBackBtn($submenu, $expandBtn),
        createElement({ tag: 'span', classes: [CLASSES.menuControlPanelTitle], textContent: menuTextLink }),
        createCloseBtn([CLASSES.menuControlPanelCloseBtn]),
      ]
    });
  }

  function createPromo(promotionpath) {
    if (!promotionpath) return;

    const $promoImg = createElement({
      tag: 'img', classes: [CLASSES.promoImg], attributes: {
        'src': `${$wwwURL}${promotionpath?.image?._path}`,
        'alt': promotionpath?.title
      }
    });
    const $promoTitle = createElement({ tag: 'div', classes: [CLASSES.promoTitle], textContent: promotionpath?.title });
    const $promoDescription = createElement({ tag: 'div', classes: [CLASSES.promoDescription], innerHTML: promotionpath?.description?.html });
    const $promoLink = createElement({
      tag: 'a',
      classes: [CLASSES.promoLink],
      textContent: promotionpath.ctaText,
      attributes: {
        href: normalizeLink({
          princesscomAppLinks: promotionpath.princesscomAppLinks || "www",
          isExternal: promotionpath.isExternal,
          externalUrl: promotionpath.externalUrl,
          externalPath: promotionpath.externalPath,
          internalPath: promotionpath.internalPath
        }),
        [ATTRIBUTES.ariaLabel]: `Learn more ${promotionpath?.title || ''}`,
        [ATTRIBUTES.dataComponentLevel]: "primaryNav",
        [ATTRIBUTES.dataTrack]: promotionpath?.title?.toLowerCase().replace(/\s+/g, '-'),
        [ATTRIBUTES.dataTrackId]: promotionpath?.ctaText?.toLowerCase().replace(/\s+/g, '-')
      }
    })

    return createElement({
      tag: 'div', classes: [CLASSES.promo], children: [
        $promoImg,
        $promoTitle,
        $promoDescription,
        $promoLink,
      ]
    });
  }

  function createMenu({
    menuLinks,
    ulClasses = [],
    liClasses = [],
    layer = 1,
    promotionpath,
  }) {
    if (!menuLinks || !Array.isArray(menuLinks)) return;
    const $ul = createElement({ tag: 'ul', classes: ulClasses });
    let $promo;

    for(let i = 0; i < menuLinks.length; i++) {
      const $li = createElement({ tag: 'li', classes: liClasses });
      const menuLink = menuLinks[i];

      if (promotionpath) {
        $promo = promotionpath
      }

      if (menuLink.sublink && menuLink.sublink?.length) {
        const $submenu = createMenu({
          menuLinks: menuLink.sublink,
          ulClasses: [CLASSES.submenu, CLASSES.layer + '-' + (layer + 1)],
          liClasses: [CLASSES.submenuItem, CLASSES.layer + '-' + (layer + 1)],
          layer: layer + 1,
          promotionpath:  (menuLink.haspromotion ? menuLink.promotionpath : undefined) || $promo,
          eventListeners: {
            'click': function(event) {
              if(PAGE_WIDTH < BREAKPOINTS.xs) {
                event.stopPropagation();
              }
            }
          }
        });

        const $expandBtn = createElement({
          tag: 'button', attributes: {
            [ATTRIBUTES.ariaExpanded]: "false",
            ...(layer !== 1 && { [ATTRIBUTES.dataComponentLevel]: "primaryNav-dropdown" }),
            [ATTRIBUTES.dataTrack]: layer === 1 ? "primaryNav-dropdown" : menuLink.menuTextLink.toLowerCase().replace(/\s+/g, '-') + '-dropdown',
            [ATTRIBUTES.dataTrackId]: menuLink.menuTextLink.toLowerCase().replace(/\s+/g, '-'),
            "aria-label": "dropdown button"
          },
          classes: [CLASSES.expandBtn, CLASSES.layer + '-' + layer],
          children: [
            createElement({ tag: 'div', classes: [CLASSES.expandBtnText], textContent: menuLink.menuTextLink }),
            createElement({
              tag: 'span', classes: [CLASSES.chevron],
              innerHTML: `<svg aria-hidden="true" focusable="false" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z"/>
            </svg>`
            })
          ]
        });

        $expandBtn.addEventListener('click', layer === 1 ? handleClickExpandMenuBtn($submenu, $expandBtn) : handleClickExpandSubmenuBtn({$submenu, $expandBtn, layer}));

        $expandBtn.addEventListener('keyup', function (event) {
          const handleKeyup = layer === 1 ? handleClickExpandMenuBtn($submenu,$expandBtn) : handleClickExpandSubmenuBtn({$submenu, $expandBtn, layer});
          if(event.keyCode === 27 && $expandBtn.getAttribute(ATTRIBUTES.ariaExpanded) === "true") {
            handleKeyup(event);
          }
        });

        if (layer >= 2) {
          const $menuControlPanel = createMenuControlPanel({
            menuTextLink: menuLink.menuTextLink,
            $submenu,
            $expandBtn
          });
          $submenu.insertBefore($menuControlPanel, $submenu.firstChild);
        }

        $li.appendChild($expandBtn);

        $li.appendChild($submenu);
      } else {
        const $tooltip = menuLink.flagMessage ? createElement({ tag: 'div', classes: [CLASSES.tooltip, 'disabled'], textContent: menuLink.flagMessage }) : null;

        const $link = createElement({
          tag: 'a', 
          classes: [CLASSES.link, CLASSES.layer + '-' + layer],
          attributes: {
            href: normalizeLink({
              princesscomAppLinks: menuLink.princesscomAppLinks,
              isExternal: menuLink.isExternal,
              externalUrl: menuLink.externalUrl,
              externalPath: menuLink.externalPath,
              internalPath: menuLink.internalPath,
            }),
            ...(layer > 1 && { [ATTRIBUTES.dataComponentLevel]: layer === 2 ? "primaryNav" : "secondaryNav" }),
            [ATTRIBUTES.dataTrack]: layer === 1 ? "primaryNav" : layer === 2 ? "secondaryNav" : "tertiaryNav",
            [ATTRIBUTES.dataTrackId]: menuLink?.menuTextLink?.toLowerCase().replace(/\s+/g, '-'),
            "aria-label": menuLink?.menuTextLink
          },
          children: [
            createElement({
              tag: 'span',
              classes: [CLASSES.linkText],
              textContent: menuLink.menuTextLink,
            })
          ]
        });

        $li.appendChild($link);

        if($tooltip) {
          $link.appendChild($tooltip);
          $li.addEventListener('mouseover', () => {
            const top = $li.getBoundingClientRect().top;
            const height = parseInt(window.getComputedStyle($tooltip)?.height?.match(/\d+/)[0]);

            if(top < height) {
              $tooltip.style.top = '120%';
              $tooltip.classList.add(CLASSES.down);
            } else {
              $tooltip.style.top = '-25%';
              $tooltip.classList.add(CLASSES.up);
            }
          })
        };

      }

      $ul.appendChild($li);
    }

    if (layer === 2 && PAGE_WIDTH >= BREAKPOINTS.xs) {
      if ($promo)  $ul.appendChild(createPromo($promo));
      $ul.appendChild(createCloseBtn([CLASSES.closeBtn, CLASSES.desktop]));
    }

    if (layer === 3 && PAGE_WIDTH < BREAKPOINTS.xs) {
      if ($promo) $ul.appendChild(createPromo($promo));
    }

    return $ul;
  }

  class MobileNavMenu extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
      this.loadGlobalHeader();
      window.addEventListener('load', async () => {
        setTimeout(() => {
          refineMenuItems();
        }, 2000);
      });
    }

    async loadGlobalHeader() {
      try {
        const globalHeader = await fetchGlobalHeader();
        this.render(globalHeader || FALLBACK_GLOBAL_HEADER);
      } catch (error) {
        console.error(error);
      }
    }

    render(globalHeader) {
      const $menu = createMenu({
        menuLinks: getMainBookingMenuLinks(globalHeader),
        ulClasses: [CLASSES.menu, CLASSES.layer + '-' + 1],
        liClasses: [CLASSES.menuItem, CLASSES.layer + '-' + 1],
        layer: 1
      });

      const $nav = createElement({
        tag: 'nav',
        classes: [CLASSES.nav],
        children: [
          $menu,
        ]
      });

      this.appendChild($nav);
    }

  }

  class NavMenu extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
      window.addEventListener('load', async () => {
        try {
          const globalHeader = await fetchGlobalHeader();
          this.render(globalHeader || FALLBACK_GLOBAL_HEADER);
          setTimeout(() => {
            refineMenuItems();
          }, 2000);
        } catch (error) {
          console.error(error);
        }
      })
    }

    render(globalHeader) {
      const $menu = createMenu({
        menuLinks: getMainBookingMenuLinks(globalHeader),
        ulClasses: [CLASSES.menu, CLASSES.layer + '-' + 1],
        liClasses: [CLASSES.menuItem, CLASSES.layer + '-' + 1],
        layer: 1
      });

      const $header = createElement({
        tag: 'div',
        classes: [CLASSES.header],
        children: [
          createElement({
            tag: 'div',
            classes: [CLASSES.menuWrapper],
            children: [
              $menu,
            ]
          }),
        ]
      });

      const $nav = createElement({
        tag: 'nav',
        classes: [CLASSES.nav],
        children: [
          $header
        ]
      });

      this.appendChild($nav);
    }

  }

  if (!window.customElements.get(MOBILE_MB_NAV_MENU)) {
    customElements.define(MOBILE_MB_NAV_MENU, MobileNavMenu);
  }

  if (!window.customElements.get(MB_NAV_MENU)) {
    customElements.define(MB_NAV_MENU, NavMenu);
  }

  function refineMenuItems() {
    let displayMenu = true;
        if (typeof $('#cp-header').data('display-menu') != 'undefined') {
            displayMenu = $('#cp-header').data('display-menu');
        }

    let results = JSON.parse(sessionStorage.getItem('menuData'));
    if(results){
        const menu = results.data.menu;
        const mbcheckinBtn = document.querySelectorAll('.mb-checkin-action');
        const mbPaymentBtn = document.querySelectorAll('.mb-payment-action');
        const itineraryLinks = document.querySelectorAll('nav a[href*="/itinerary.page"]');
        const paxCheckIn = document.querySelectorAll('nav a[href*="/paxCheckIn.page"]');
        const excursions = document.querySelectorAll('nav a[href*="/cruise-excursions/cp-landing"]');
        const spa = document.querySelectorAll('nav a[href*="/spa.page"]');

        for (let link in menu) {
            if (menu[link].display) {
                if (link == 'excursionRes' && results.data.daysToVoyage < 6) {
                    excursions.forEach(link => {
                      const exToolTip = link.querySelector(CLASSES.tooltip);
                      if (exToolTip) {
                          exToolTip.classList.remove('disabled');
                          link.classList.add('hasTooltip');
                        }
                    });
                } else if (link == 'excursionRes' && results.data.daysToVoyage > 6) {
                    excursions.forEach(link => {
                        const exToolTip = link.querySelector(CLASSES.tooltip);
                        if (exToolTip) {
                            if (!exToolTip.classList.contains('disabled')) {
                              exToolTip.classList.add('disabled');
                              link.classList.remove('hasTooltip');
                            }
                        }
                    });
                }
            } else {
                switch (link) {
                    case 'itinerary':
                        itineraryLinks.forEach(link => {
                            link.closest('li').style.display = 'none';
                        });
                        break;
                    case 'paxCheckin':
                        paxCheckIn.forEach(link => {
                            link.closest('li').style.display = 'none';
                        });
                        mbcheckinBtn.forEach(link => {
                            link.style.display = 'none';
                        });
                        break;
                    case 'excursionRes':
                        excursions.forEach(link => {
                            link.closest('li').style.display = 'none';
                        });
                        break;
                    case 'spa':
                      spa.forEach(link => {
                          link.closest('li').style.display = 'none';
                      });
                      break;
                }
            }
        }

        if (displayMenu == true) {
            document.querySelector('mb-global-header').style.display = 'block';
        } else {
            document.querySelector(MB_NAV_MENU)?.remove();
            document.querySelector(MOBILE_MB_NAV_MENU)?.remove();
            document.querySelector('.mb-alerts-container')?.remove();
            document.querySelector('.mb-booking-info')?.remove();
            document.querySelector('.mb-booking-mobile-wrapper')?.remove();
            mbcheckinBtn.forEach(link => {
                link.style.display = 'none';
            });
            mbPaymentBtn.forEach(link => {
                link.style.display = 'none';
            });
        }
    }
  }
}());
